import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import HighchartsVue from 'highcharts-vue'
import { VTooltip } from 'floating-vue'
import 'floating-vue/dist/style.css'
import './style/style.css'

const app = createApp(App)

app.config.globalProperties.$isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

app.use(HighchartsVue)
app.directive('hint', VTooltip)

app.use(store).use(router).mount('#app')
